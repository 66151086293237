
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexnL76Dhk4utMeta } from "/vercel/path0/pages/about-us/index.vue?macro=true";
import { default as accountLUTTRGMhg3Meta } from "/vercel/path0/pages/account.vue?macro=true";
import { default as indexs8g6T0dsz8Meta } from "/vercel/path0/pages/faq/index.vue?macro=true";
import { default as favoritesHPWwAOvQDDMeta } from "/vercel/path0/pages/favorites.vue?macro=true";
import { default as indexr8byqibT9RMeta } from "/vercel/path0/pages/how-to-make-a-video/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as copyright2vp4gtTKYCMeta } from "/vercel/path0/pages/legal/copyright.vue?macro=true";
import { default as icmjevCMIGD5HLVMeta } from "/vercel/path0/pages/legal/icmje.vue?macro=true";
import { default as indexGnHiNaJdjPMeta } from "/vercel/path0/pages/legal/index.vue?macro=true";
import { default as privacyEXft7RtKWLMeta } from "/vercel/path0/pages/legal/privacy.vue?macro=true";
import { default as securityomyKRhA7hcMeta } from "/vercel/path0/pages/legal/security.vue?macro=true";
import { default as checklistCSsoN1MJuBMeta } from "/vercel/path0/pages/upload/checklist.vue?macro=true";
import { default as indexDmBXjFm2e6Meta } from "/vercel/path0/pages/upload/index.vue?macro=true";
import { default as preview0RocXyS5SAMeta } from "/vercel/path0/pages/upload/preview.vue?macro=true";
import { default as thank_45youqMCJWegJTYMeta } from "/vercel/path0/pages/upload/thank-you.vue?macro=true";
import { default as uploadingLr6EHFbOM9Meta } from "/vercel/path0/pages/upload/uploading.vue?macro=true";
import { default as videoVqf9GpYdT7Meta } from "/vercel/path0/pages/upload/video.vue?macro=true";
import { default as uploadsnjhhbEOLgFMeta } from "/vercel/path0/pages/uploads.vue?macro=true";
import { default as _91_46_46_46slug_939gRSBI01nOMeta } from "/vercel/path0/pages/videos/[...slug].vue?macro=true";
import { default as component_45stub5X4Ei38PMgMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub5X4Ei38PMg } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/vercel/path0/pages/about-us/index.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/vercel/path0/pages/account.vue")
  },
  {
    name: "faq",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq/index.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/vercel/path0/pages/favorites.vue")
  },
  {
    name: "how-to-make-a-video",
    path: "/how-to-make-a-video",
    component: () => import("/vercel/path0/pages/how-to-make-a-video/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "legal-copyright",
    path: "/legal/copyright",
    component: () => import("/vercel/path0/pages/legal/copyright.vue")
  },
  {
    name: "legal-icmje",
    path: "/legal/icmje",
    component: () => import("/vercel/path0/pages/legal/icmje.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/vercel/path0/pages/legal/index.vue")
  },
  {
    name: "legal-privacy",
    path: "/legal/privacy",
    component: () => import("/vercel/path0/pages/legal/privacy.vue")
  },
  {
    name: "legal-security",
    path: "/legal/security",
    component: () => import("/vercel/path0/pages/legal/security.vue")
  },
  {
    name: "upload-checklist",
    path: "/upload/checklist",
    component: () => import("/vercel/path0/pages/upload/checklist.vue")
  },
  {
    name: "upload",
    path: "/upload",
    component: () => import("/vercel/path0/pages/upload/index.vue")
  },
  {
    name: "upload-preview",
    path: "/upload/preview",
    component: () => import("/vercel/path0/pages/upload/preview.vue")
  },
  {
    name: "upload-thank-you",
    path: "/upload/thank-you",
    component: () => import("/vercel/path0/pages/upload/thank-you.vue")
  },
  {
    name: "upload-uploading",
    path: "/upload/uploading",
    component: () => import("/vercel/path0/pages/upload/uploading.vue")
  },
  {
    name: "upload-video",
    path: "/upload/video",
    component: () => import("/vercel/path0/pages/upload/video.vue")
  },
  {
    name: "uploads",
    path: "/uploads",
    component: () => import("/vercel/path0/pages/uploads.vue")
  },
  {
    name: "videos-slug",
    path: "/videos/:slug(.*)*",
    component: () => import("/vercel/path0/pages/videos/[...slug].vue")
  },
  {
    name: component_45stub5X4Ei38PMgMeta?.name,
    path: "/sitemap.xml",
    component: component_45stub5X4Ei38PMg
  }
]